import React, { useEffect, useState } from "react";
import { Tabs, Tab, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function Ingredients({data}) {
    const [statements, setStatements] = useState(undefined);
    useEffect(()=>{
        if( data !== undefined) {
            let nonFood = []
            let food = []
            data.map((statement) => {
                if(statement.nonFoodIngredient) {
                    nonFood.push(statement);
                } else {
                    food.push(statement);
                }
            })

            let newStatements = {}
            newStatements.nonFood = nonFood;
            newStatements.food = food;
            setStatements(newStatements);
        }
    }, [data])

    return  statements !== undefined ?
                <Grid container item direction="column"> 
                    {statements.nonFood.length > 0 ?
                        <Grid item container direction="column" rowSpacing={2}>
                            <Grid item><Typography variant="PDTitle">Non Food Ingredients</Typography></Grid>
                            <Grid item>
                                <Typography variant="PDValue">
                                    {statements.nonFood.map((elem) => (
                                        elem["ingredientStatement"]
                                    ))}
                                </Typography>
                            </Grid>
                        </Grid>
                    :
                        ''
                    }
                    {statements.food.length > 0 && statements.food[0].ingredientStatement !== '' ?
                        <Grid item container direction="column" rowSpacing={2}>
                            <Grid item><Typography variant="PDTitle">Food Ingredients</Typography></Grid>
                            <Grid item>
                                <Typography  variant="PDValue">
                                    {statements.food.map((elem) => (
                                        elem["ingredientStatement"]
                                    ))}
                                </Typography>
                            </Grid>
                        </Grid>
                    :
                        ''
                    }
                </Grid>
            :
                ''
}