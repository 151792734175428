import React, { useState, useEffect } from "react";
import ToolsApi from './ToolsApi';
import config from "../config/config"

export default function toolsProductBrowserApi() {
    const { data, post, get } = ToolsApi();

    const getAvailableGlns = async (query) => {
        let url = config.API_URL + "/api/user/available-glns";
        
        let body = undefined
        return get(url, query, body);
    };
    const getDetailsFilter = async (body) => {
        let url = config.API_URL + "/api/filter_product_details";
        
        let query = undefined
        return post(url, query, body);
    };
    const searchProducts = async (body) => {
        let url = config.API_URL + "/api/search";
        
        let query = undefined
        body = {...body, source: 'web'}
        return post(url, query, body);
    };

    const exportMulitPdf = async (body) => {
        let url = config.API_URL + "/api/exportpdfmultifiles";
        
        let query = undefined
        return post(url, query, body);
    };

    const getCategoriesByGln = async (body) => {
        let url = config.API_URL + "/api/filter_maincat";
        
        let query = undefined
        return post(url, query, body);
    };

    const getPublishedGlns = async (query) => {
        let url = config.API_URL + "/api/user/published-glns";
        
        let body = undefined
        return get(url, query, body);
    };

    return {
        getAvailableGlns,
        getDetailsFilter,
        searchProducts,
        exportMulitPdf,
        getCategoriesByGln,
        getPublishedGlns
    };
}